














































































































































































































































































































































































































































































































// import crypto from 'crypto';
// import qs from 'qs';
import Vue from 'vue';
import { mapState } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { hotCity, hotCountry } from './data';
import EveFair from './component/eveFair.vue';
import NewsList from './component/NewsList.vue';
import { ResponseCode } from '~/util/constant';
import {
  checkDevice,
  OSS_BUCKET_URL,
  handleApiError,
  handleNodeServerError,
} from '~/util/common';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  components: {
    EveFair,
    NewsList,
  },
  // middleware: 'pageCache',
  filters: {
    formatTime(val: string) {
      return moment(new Date(val)).format('YYYY-MM-DD');
    },
    subIndustry(idx: number) {
      const index = idx % 5;
      switch (index) {
        case 0:
          return 'transform: translateX(-8%);';
        case 1:
          return 'transform: translateX(-16%);';
        case 2:
          return 'transform: translateX(-42%);';
        case 3:
          return 'transform: translateX(-22%);';
        case 4:
          return 'transform: translateX(-58%);';
      }
    },
    contactName(name: string) {
      if (typeof name !== 'string') return '';
      try {
        const newName = name.replace(/ /g, '');
        if (newName.length === 2) {
          return newName + this.$nuxt.$t('home.hot_recommend.country_suffix3');
        } else if (newName.length === 3) {
          return newName + this.$nuxt.$t('home.hot_recommend.country_suffix2');
        } else {
          return newName + this.$nuxt.$t('home.hot_recommend.country_suffix1');
        }
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return name;
      }
    },
  },
  async asyncData(ctx) {
    try {
      const lang = ctx.query.lang;
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';

      const currentDate = moment().add(7, 'days').format('yyyy-MM-DD');
      // area国内传cityId,国际传areaId(国家id);type 1国内, 0国际
      const getAreaFairList = (area: string, type: number) => {
        let params: any = {
          locale: langApi,
          selectTimeType: 1,
          // area,
          // cityId: area,
          pageNum: 1,
          pageSize: 2,
          time: currentDate,
          sortStr: 'scale',
          sortType: 'asc',
        };
        if (type == 1) params['cityId'] = area;
        if (type == 0) params['area'] = area;

        return ctx.$axios.post('/api/exhibition/list', params);
      };
      const getNewsList = (newsType?: string, pageSize = 10) => {
        // 咨询类型 展会资讯 industry 行业动态 industryNews 展会政策 policy 展会知识 knowledge
        return ctx.$axios.post('/api/newsApi/homePageList', {
          locale: langApi,
          newsType,
          pageNum: 1,
          pageSize,
        });
      };

      /*     const odlApi = async (action: string) => {
        const md5 = crypto.createHash('md5');
        const timestamp = Date.now();
        const version = '1.0';
        const token = md5
          .update(
            `${action}${version}${timestamp}89D81FBC94E57BD3AA0F32F4CE078C34`
          )
          .digest('hex');
        

        return await ctx.$axios.post(
          `/v1/`,
          qs.stringify({
            action,
            version,
            token,
            timestamp,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
          }
        );
      }; */

      // 资讯类型： 0:展会资讯 industry 1:行业动态 industryNews 2:展会政策 policy 3:展会知识 knowledge
      const allRes: any = await Promise.all([
        ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`), // 所有行业
        // ctx.$axios.get('/api/banner/bannerDetail/1'), // 首页Banner
        ctx.$axios.post('/api/bannerApi/page', {
          type: 0,
          channel: 0,
        }), // 首页Banner
        ctx.$axios.post('/api/exhibition/list', {
          locale: langApi,
          selectTimeType: 1,
          recommond: '1',
          pageNum: 1,
          pageSize: 20,
          sortScale: 'desc',
          // sortStr: 'scale',
          // sortType: 'asc',
        }), // 热门推荐
        getAreaFairList('1123', 1), // 国内->上海
        getAreaFairList('1051', 1), // 国内->北京
        getAreaFairList('1252', 1), // 国内->深圳
        getAreaFairList('73', 0), // 国际->德国
        getAreaFairList('9', 0), // 国际->日本
        getAreaFairList('127', 0), // 国际->美国
        getNewsList('1', 20), // 最新资讯
        // getNewsList('3'), // 行业资讯
        ctx.$axios.post('/api/exhibition/page', {
          locale: langApi,
          pageNum: 1,
          pageSize: 10,
        }),
        // getNewsList('4'), // 展会政策
        // odlApi('region.getHotCountry'), // 热门国家
        // odlApi('region.getHotCity'), // 热门城市
        ctx.store.dispatch('global/getLinks', { id: 0 }),
      ]);

      const [
        industryRes,
        bannerRes,
        hotFairRes,
        shFairRes,
        bjFairRes,
        szFairRes,
        dgFairRes,
        rbFairRes,
        mgFairRes,
        newsRes,
        // industryNewsRes,
        policyNewsRes,
      ] = allRes;
      let apiErrorList: any = [];
      let industry = [];
      if (industryRes.code === ResponseCode.SUCCESS) {
        industry = industryRes.data;
      }

      const banner = [];

      if (bannerRes.code === ResponseCode.SUCCESS && Array.isArray(bannerRes.data)) {
        for (let i = 0; i < bannerRes.data.length; i++) {
          const url = bannerRes.data[i].bannerUrlList;
          const link = bannerRes.data[i].bannerLinkListPc;
          banner.push({
            url,
            link,
          });
        }
      }

      let hotFair = {};
      if (hotFairRes.code === ResponseCode.SUCCESS) {
        hotFair = hotFairRes.data;
      } else {
        apiErrorList.push({
          code: hotFairRes.code,
          message: hotFairRes.message,
        });
      }

      let shFair = {};
      if (shFairRes.code === ResponseCode.SUCCESS) {
        shFair = shFairRes.data;
      } else {
        apiErrorList.push({ code: shFairRes.code, message: shFairRes.message });
      }

      let bjFair = {};
      if (bjFairRes.code === ResponseCode.SUCCESS) {
        bjFair = bjFairRes.data;
      } else {
        apiErrorList.push({ code: bjFairRes.code, message: bjFairRes.message });
      }

      let szFair = {};
      if (szFairRes.code === ResponseCode.SUCCESS) {
        szFair = szFairRes.data;
      } else {
        apiErrorList.push({ code: szFairRes.code, message: szFairRes.message });
      }

      let dgFair = {};
      if (dgFairRes.code === ResponseCode.SUCCESS) {
        dgFair = dgFairRes.data;
      } else {
        apiErrorList.push({ code: dgFairRes.code, message: dgFairRes.message });
      }

      let rbFair = {};
      if (rbFairRes.code === ResponseCode.SUCCESS) {
        rbFair = rbFairRes.data;
      } else {
        apiErrorList.push({ code: rbFairRes.code, message: rbFairRes.message });
      }

      let mgFair = {};
      if (mgFairRes.code === ResponseCode.SUCCESS) {
        mgFair = mgFairRes.data;
      } else {
        apiErrorList.push({ code: mgFairRes.code, message: mgFairRes.message });
      }

      let news = [];
      if (newsRes.code === ResponseCode.SUCCESS && newsRes.data && newsRes.data.records) {
        news = newsRes.data.records.map(
          (item: {
            newsId: any;
            viewUrl: any;
            newsName: any;
            publishTime: any;
          }) => {
            const isToday = moment().diff(moment(item.publishTime), 'days');
            const format = isToday > 1 ? 'MM-DD HH:mm' : 'HH:mm';

            return {
              newsId: item.newsId,
              viewUrl: item.viewUrl,
              newsName: item.newsName,
              createTime: moment(item.publishTime).format(format),
            };
          }
        );
      }

      // let industryNews = {};
      // if (industryNewsRes.code === ResponseCode.SUCCESS && industryNewsRes.data && industryNewsRes.data.records) {
      //   industryNews = industryNewsRes.data.records.map(
      //     (item: { newsId: any; newsName: any; publishTime: any }) => ({
      //       newsId: item.newsId,
      //       newsName: item.newsName,
      //       createTime: moment(new Date(item.publishTime)).format('YYYY-MM-DD'),
      //     })
      //   );
      // }

      let policyNews = {};
      if (policyNewsRes.code === ResponseCode.SUCCESS && policyNewsRes.data && policyNewsRes.data.records) {
        policyNews = policyNewsRes.data.records.map(
          (item: {
            exhibitionId: any;
            exhibitionName: any;
            startTime: any;
          }) => ({
            exhibitionId: item.exhibitionId,
            exhibitionName: item.exhibitionName,
            startTime: item.startTime,
          })
        );
      }

      // let hotCountry = {};
      // if (hotCountryRes.code === 1) {
      //   hotCountry = hotCountryRes.data;
      // }

      // let hotCity = {};
      // if (hotCityRes.code === 1) {
      //   hotCity = hotCityRes.data;
      // }

      //
      // 内链
      let innerChainActive: number = 0;
      const innerChainAllRes: any = await Promise.all([
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门地区',
          pageSize: 16,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门行业',
          pageSize: 16,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门展会',
          pageSize: 16,
        }),
      ]);

      const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
        innerChainAllRes;
      let innerChainAreaList = [];
      let innerChainIndustryList = [];
      let innerChainExhList = [];
      if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
        innerChainAreaList = innerChainAreaRes.data.records;
      }
      if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
        innerChainIndustryList = innerChainIndustryRes.data.records;
      }
      if (innerChainExhRes.code === ResponseCode.SUCCESS) {
        innerChainExhList = innerChainExhRes.data.records;
      }

      let interfaceResponseTime = ctx.store.state.global.interfaceResponseTime;
      // 设置一个自定义响应头(nuxtTraceId 记录日志位置)
      if (ctx.res) ctx.res.setHeader('nuxtTraceId', interfaceResponseTime);
      if (ctx.$winstonLog) ctx.$winstonLog.info(interfaceResponseTime);
      ctx.store.commit('global/setInterfaceResponseTime', '');

      if (apiErrorList.length > 0)
        handleNodeServerError(ctx, JSON.stringify(apiErrorList));

      return {
        lang,
        industry,
        banner,
        hotFair,
        shFair,
        bjFair,
        szFair,
        dgFair,
        rbFair,
        mgFair,
        news,
        // industryNews,
        policyNews,
        hotCountry,
        hotCity,
        innerChainActive,
        innerChainAreaList,
        innerChainIndustryList,
        innerChainExhList,
        apiErrorList,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data() {
    return {
      keyword: '',
      keywordError: false,
      keywordPlaceholder: this.$t('home.top_container.inputPlaceholder'),
      OSS_BUCKET_URL,
      news: [],
      innerChainActive: 0,
      apiErrorList: null,
    };
  },
  computed: {
    // ...mapState({
    //   isLogin: (state: any) => state.global.isLogin,
    // }),
  },
  created() {
    if (process.client) {
      // console.log('872-');
    }
  },
  mounted() {
    if (checkDevice() == 'h5') {
      let url = `https://m.jufair.com`;
      window.location.replace(url);
    }

    this.$store.commit(
      'global/setApiErrorMessage',
      JSON.stringify(this.apiErrorList)
    );
    handleApiError(this.apiErrorList);
    // 埋点
    window.trace?.upload({
      module: TraceCode.MO_HOME,
    });
  },
  methods: {
    handleSearch: _.debounce(
      function () {
        // @ts-ignore
        const { keyword, $message } = this;
        // if (!isLogin) {
        //   return $login.show();
        // }
        if (!/^[0-9a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword)) {
          // @ts-ignore
          this.keywordError = true;
          // @ts-ignore
          this.$message({
            type: 'error',
            // @ts-ignore
            message: this.$t('menu.keyword_error1'),
            offset: 500,
          });
          return;
        } else {
          // @ts-ignore
          this.keywordError = false;
        }
        if (keyword.length > 50) {
          // @ts-ignore
          this.keywordError = true;
          // @ts-ignore
          this.$message({
            type: 'error',
            // @ts-ignore
            message: this.$t('menu.keyword_error2'),
            offset: Math.ceil(window.innerHeight / 2),
          });
          return;
        }
        if (keyword) {
          window.open(`/exhibition/?keyword=${keyword}`, '_blank');
        } else {
          // @ts-ignore
          $message.warning(<string>this.$t('menu.keywordPlaceholder1'));
        }
      },
      500,
      { leading: true }
    ),
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },
    handleKeywordInput() {
      const { keyword } = this;
      if (!keyword) {
        this.keywordError = false;
        this.keywordPlaceholder = this.$t('menu.keywordPlaceholder1');
      } else if (keyword.length > 50) {
        this.keywordError = true;
        this.keywordPlaceholder = this.$t('menu.keyword_error2');
      } else {
        this.keywordError = !/^[a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword);
        this.keywordPlaceholder = this.$t('menu.keywordPlaceholder3');
      }
    },
  },
  head() {
    return {
      title: <string>this.$t('tdk.home.title'),
      meta: [
        {
          name: 'mobile-agent',
          'http-equiv': 'mobile-agent',
          content: 'format=html5; url=https://m.jufair.com',
        },
        {
          name: 'keywords',
          content: <string>this.$t('tdk.home.keywords'),
        },
        {
          name: 'description',
          content: <string>this.$t('tdk.home.description'),
        },
      ],
    };
  },
});
